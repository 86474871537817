import logo from './images/karzone.png'
function Footer() {
  return (
    <>
      <footer className="footer bg-2 bg-overlay-black-90">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="social">
                <ul>
                  <li>
                    <a className="facebook" href="/">
                      facebook <i className="fa fa-facebook" />{" "}
                    </a>
                  </li>
                  <li>
                    <a className="twitter" href="/">
                      twitter <i className="fa fa-twitter" />{" "}
                    </a>
                  </li>
                  <li>
                    <a className="pinterest" href="/">
                      pinterest <i className="fa fa-pinterest-p" />{" "}
                    </a>
                  </li>
                  <li>
                    <a className="dribbble" href="/">
                      dribbble <i className="fa fa-dribbble" />{" "}
                    </a>
                  </li>
                  <li>
                    <a className="google-plus" href="/">
                      google plus <i className="fa fa-google-plus" />{" "}
                    </a>
                  </li>
                  <li>
                    <a className="behance" href="/">
                      behance <i className="fa fa-behance" />{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="about-content">
                <h2 style={{color:"white"}}>KAR ZONE</h2>
                <p>
                We guarantee the best used car deals
Choose from all used cars available.
The price you see is the price you pay - no hidden fees
Low-rate car finance available from 9.9% APR
Whether it’s routine servicing, MOTs, mechanical repairs, tyres or parts, get in touch for a competitive quote. And if you’ve had a minor bump or scrape, we can come to you and get your vehicle looking as good as new, from the comfort of your home or office.

Thank you for visiting our website, we hope you find what you’re looking for.
                </p>
              </div>
              <div className="address">
                <ul>
                  <li>
                    {" "}
                    <i className="fa fa-map-marker" />
                    <span> Sandy Train Station Car Park, Station Road, Sandy, Bedfordshire, SG19 1AW</span>{" "}
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-phone" />
                    <span> 07555 444087</span>{" "}
                  </li>
                  <li>
                    {" "}
                    Info@karzone.co.uk
                    <i className="fa fa-envelope-o" />
                    <span></span>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="usefull-link">
                <h6 className="text-white">Useful Links</h6>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-angle-double-right" /> Change Oil and
                      Filter
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-angle-double-right" /> Brake Pads
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-angle-double-right" /> Timing Belt
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-angle-double-right" /> Pre-purchase Car
                      Inspection
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-angle-double-right" /> Starter Replacement
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="recent-post-block">
                <h6 className="text-white">recent posts </h6>
                <div className="recent-post">
                  <div className="recent-post-image">
                    <img className="img-fluid" src="images/car/01.jpg" alt="" />
                  </div>
                  <div className="recent-post-info">
                    <a href="/">Time to change your </a>
                    <span className="post-date">
                      <i className="fa fa-calendar" />
                      December 20, 2023
                    </span>
                  </div>
                </div>
                <div className="recent-post">
                  <div className="recent-post-image">
                    <img className="img-fluid" src="images/car/02.jpg" alt="" />
                  </div>
                  <div className="recent-post-info">
                    <a href="/">The best time to </a>
                    <span className="post-date">
                      <i className="fa fa-calendar" />
                      December 20, 2023
                    </span>
                  </div>
                </div>
                <div className="recent-post">
                  <div className="recent-post-image">
                    <img className="img-fluid" src="images/car/03.jpg" alt="" />
                  </div>
                  <div className="recent-post-info">
                    <a href="/">Replacing a timing </a>
                    <span className="post-date">
                      <i className="fa fa-calendar" />
                      December 20, 2023
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="news-letter">
                <h6 className="text-white">subscribe Our Newsletter </h6>
                <p>
                  Keep up on our always evolving products features and technology.
                  Enter your e-mail and subscribe to our newsletter.
                </p>
                <form className="news-letter">
                  <input
                    type="email"
                    placeholder="Enter your Email"
                    className="form-control placeholder"
                  />
                  <a className="button red" href="/">
                    Subscribe
                  </a>
                </form>
              </div>
            </div>
          </div>
          <hr />
        
        </div>
      </footer>

    </>
  )
}

export default Footer
