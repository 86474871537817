import React from 'react'
import pic2 from './images/Car3.jpg'
import pic3 from './images/Kia1.jpg'
import pic4 from './images/audi.jpg'
import pic5 from './images/marcedes.jpg'
import pic6 from './images/marcedes3.jpg'
import pic7 from './images/mercedes2.jpg'
import pic8 from './images/rangrover2.jpg'

function CarList() {


  return (
    <div>
      <>
        <section className="inner-intro bg-1 bg-overlay-black-70">
          <div className="container">
            <div className="row text-center intro-title">
              <div className="col-md-6 text-md-start d-inline-block">
                <h1 className="text-white">product listing </h1>
              </div>
              <div className="col-md-6 text-md-end float-end">
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <a href="/">pages</a> <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>listing 01</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
 inner-intro */}
        {/*=================================
product-listing  */}
        <section className="product-listing page-section-ptb">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="listing-sidebar">
                  <div className="widget">
                    <div className="widget-search">
                      <h5>Advanced Search</h5>
                      <ul
                        className="list-style
       -none"
                      >
                        <li>
                          <i className="fa fa-star"> </i> Results Found{" "}
                          <span className="float-end">(39)</span>
                        </li>
                        <li>
                          <i className="fa fa-shopping-cart"> </i> Compare Vehicles{" "}
                          <span className="float-end">(10)</span>
                        </li>
                      </ul>
                    </div>
                    <div className="clearfix">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <a href="/">Year</a>
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck01"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck01"
                                >
                                  All Years
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck02"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck02"
                                >
                                  2009
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck03"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck03"
                                >
                                  2010
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck04"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck04"
                                >
                                  2011
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck05"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck05"
                                >
                                  2012
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck06"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck06"
                                >
                                  2013
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck51"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck51"
                                >
                                  2014
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck07"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck07"
                                >
                                  2015
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck08"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck08"
                                >
                                  2016
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="list-group-item">
                          <a href="/">Condition</a>
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck09"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck09"
                                >
                                  All Conditions
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck10"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck10"
                                >
                                  Brand New
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck11"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck11"
                                >
                                  Slightly Used
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck12"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck12"
                                >
                                  Used
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="list-group-item">
                          <a href="/">Body</a>
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck13"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck13"
                                >
                                  All Body Styles
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck14"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck14"
                                >
                                  2dr Car
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck15"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck15"
                                >
                                  4dr Car
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck16"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck16"
                                >
                                  Convertible
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck17"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck17"
                                >
                                  Sedan
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck18"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck18"
                                >
                                  Sports Utility Vehicle
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="list-group-item">
                          <a href="/">Model</a>
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck19"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck19"
                                >
                                  All Models
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck20"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck20"
                                >
                                  3-Series
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck21"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck21"
                                >
                                  Boxster
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck22"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck22"
                                >
                                  Carrera
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck23"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck23"
                                >
                                  Cayenne
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck24"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck24"
                                >
                                  F-type
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck25"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck25"
                                >
                                  GT-R
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck26"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck26"
                                >
                                  GTS
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck27"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck27"
                                >
                                  M6
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck28"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck28"
                                >
                                  Macan
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck29"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck29"
                                >
                                  Mazda6
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck30"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck30"
                                >
                                  RLX
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="list-group-item">
                          <a href="/">Transmission</a>
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck52"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck52"
                                >
                                  All Transmissions
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck31"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck31"
                                >
                                  5-Speed Manual
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck32"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck32"
                                >
                                  6-Speed Automatic
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck33"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck33"
                                >
                                  6-Speed Manual
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck34"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck34"
                                >
                                  6-Speed Semi-Auto
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck35"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck35"
                                >
                                  7-Speed PDK
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck36"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck36"
                                >
                                  8-Speed Automatic
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck37"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck37"
                                >
                                  8-Speed Tiptronic
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="list-group-item">
                          <a href="/">Exterior Color</a>
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck38"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck38"
                                >
                                  Ruby Red Metallic
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck39"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck39"
                                >
                                  Racing Yellow
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck40"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck40"
                                >
                                  Guards Red
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck41"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck41"
                                >
                                  Aqua Blue Metallic
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck42"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck42"
                                >
                                  White
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck43"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck43"
                                >
                                  Dark Blue Metallic
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="list-group-item">
                          <a href="/">Interior Color</a>
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck44"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck44"
                                >
                                  Platinum Grey
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck45"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck45"
                                >
                                  Agate Grey
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck46"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck46"
                                >
                                  Marsala Red
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck47"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck47"
                                >
                                  Alcantara Black
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck48"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck48"
                                >
                                  Black
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="invalidCheck49"
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck49"
                                >
                                  Luxor Beige
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="widget-banner">
                    <img
                      className="img-fluid center-block"
                      src="images/banner.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="sorting-options-main">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="price-slide">
                        <div className="price">
                          <label className="mb-2" htmlFor="amount">
                            Price Range
                          </label>
                          <input
                            type="text"
                            id="amount"
                            className="amount"
                            defaultValue="£50 - £300"
                          />
                          <div id="range" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="price-slide-2">
                        <div className="price">
                          <label className="mb-2" htmlFor="amount-2">
                            Price Range
                          </label>
                          <input
                            type="text"
                            id="amount-2"
                            className="amount"
                            defaultValue="£50 - £300"
                          />
                          <div id="slider-range-2" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="price-search">
                        <span className="mb-2">Price search</span>
                        <div className="search">
                          <i className="fa fa-search" />
                          <input
                            type="search"
                            className="form-control placeholder"
                            placeholder="Search...."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row sorting-options">
                    <div className="col-lg-5">
                      <div className="change-view-button">
                        <a className="active" href="/">
                          {" "}
                          <i className="fa fa-th" />{" "}
                        </a>
                        <a href="listing-02.html">
                          {" "}
                          <i className="fa fa-list-ul" />{" "}
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 text-end">
                      <div className="selected-box">
                        <select>
                          <option>Show</option>
                          <option>1</option>
                          <option>2 </option>
                          <option>3 </option>
                          <option>4 </option>
                          <option>5 </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 text-end">
                      <div className="selected-box">
                        <select>
                          <option>Sort by </option>
                          <option>Price: Lowest first</option>
                          <option>Price: Highest first </option>
                          <option>Product Name: A to Z </option>
                          <option>Product Name: Z to A </option>
                          <option>In stock</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">

                  <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic7}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">
                          Mercedes-Benz C Class 1.6 C200d SE G-Tronic+ Euro 6 (s/s) 5dr
                          FULL SERVICE HISTORY</a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">£10,490</span>
                          <span className="new-price">£9,490</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic2}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">
                          Audi A3 2.0 TDI Black Edition Euro 5 (s/s) 3dr
                          CAMBELT CHANGED</a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">££5,490</span>
                          <span className="new-price">£4,490</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic6}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">Mercedes-Benz C Class 2.0 C220d AMG Line (Premium) G-Tronic+ Euro 6 (s/s) 4dr</a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">£25,990</span>
                          <span className="new-price">£24,990</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic3}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">KIA Optima 2.0h GDi 9.8kWh Auto Euro 6 4dr </a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">£11,450</span>
                          <span className="new-price">£10,450 </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic4}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">Audi A1 1.4 TFSI Sport Sportback Euro 5 (s/s) 5dr </a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">£8,450</span>
                          <span className="new-price">£7,450</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                 
                  <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic4}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">Audi A1 1.4 TFSI Sport Sportback Euro 5 (s/s) 5dr </a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">£8,450</span>
                          <span className="new-price">£7,450</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic5}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">Mercedes-Benz E Class 2.1 E250 CDI BlueEfficiency Sport G-Tronic+ Euro 5 (s/s) 2dr</a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">£6,790</span>
                          <span className="new-price">£5,790 </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic6}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">GTA 5 Lowriders DLC</a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">£35,568</span>
                          <span className="new-price">£32,698 </span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic7}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/"> Lexus GS 450h</a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">£35,568</span>
                          <span className="new-price">£32,698 </span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic8}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">Land Rover Range Rover Evoque 2.2 SD4 Pure Auto 4WD Euro 5 5dr</a>
                        <div className="separator" />
                        <div className="price">
                          {/* <span className="old-price">£35,568</span> */}
                          <span className="new-price">price £8500 </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">  <img
                        src={pic2}
                        alt=''
                        style={{ width: '90%', height: '100%' }}
                      />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">Mercedes-Benz C Class 2.0 C220d AMG Line (Premium) G-Tronic+ Euro 6 (s/s) 4dr</a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">£35,568</span>
                          <span className="new-price">£32,698 </span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-4 col-sm-6">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          src={pic4}
                          alt=''
                          style={{ width: '90%', height: '100%' }}
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="/">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="/">
                                <i className="fa fa-shopping-cart" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-shopping-cart" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="/">Hyundai santa fe sport </a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">£35,568</span>
                          <span className="new-price">£32,698 </span>
                        </div>
                      </div>
                    </div>
                  </div> */}


                </div>

                <div className="pagination-nav d-flex justify-content-center">
                  <ul className="pagination">
                    <li>
                      <a href="/">«</a>
                    </li>
                    <li className="active">
                      <a href="/">1</a>
                    </li>
                    <li>
                      <a href="/">2</a>
                    </li>
                    <li>
                      <a href="/">3</a>
                    </li>
                    <li>
                      <a href="/">4</a>
                    </li>
                    <li>
                      <a href="/">5</a>
                    </li>
                    <li>
                      <a href="/">»</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>

    </div>




  )
}

export default CarList
