import { Link } from "react-router-dom"

function Navbar() {
  
  return (
    <>
  <header id="header" className="defualt">
    <div className="topbar">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="topbar-left text-lg-start text-center">
              <ul className="list-inline">
                <li>
                  {" "}
                  <i className="fa fa-envelope-o"> </i> Info@karzone.co.uk
                </li>
                <li>
                  {" "}
                  <i className="fa fa-clock-o" /> Mon - Sat 8.00 - 18.00. Sunday
                  CLOSED
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="topbar-right text-lg-end text-center">
              <ul className="list-inline">
                <li>
                  {" "}
                  <i className="fa fa-phone" />  07555 444087
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-youtube-play" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*=================================
 mega menu */}
    <div className="menu">
      {/* menu start */}
      <nav id="menu" className="mega-menu">
        {/* menu list items container */}
        <section className="menu-list-items">
          <div className="container">
            <div className="row">
              <div className="col-md-12 position-relative">
                {/* menu logo */}
                <ul className="menu-logo">
                  <li>
                    <a href="/">
                       <h3 style={{ color : 'white'}}>Kar Zone</h3>
                    </a>
                  </li>
                </ul>
                {/* menu links */}
                <ul className="menu-links">
                  {/* active class */}
                  <li className="active">
                   <a href="/">
                      {" "}
                      Home <i/>
                    </a>
                    {/* drop down multilevel  */}
                    
                  </li>
                  <li>
                   <Link to="/CarList">
                    Used Cars
                       <i/>
                    </Link>
                    {/* drop down full width */}
                    
                  </li>
            
                  <li>
                   <Link to="/About">
                    About Us{" "}
                      
                    </Link>
                    {/* drop down multilevel  */}
                    
                  </li>
                  <li>
                   <Link to="Contact">
                      {" "}
                      Contact 
                    </Link>
                    {/* drop down multilevel  */}
                    
                  </li>
                  <li>
                    <div className="search-top">
                      {" "}
                      <a
                        className="search-btn not_click d-none d-lg-block"
                        href="javascript:void(0);"
                      >
                        Search Button
                      </a>
                     
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </nav>
      {/* menu end */}
    </div>
  </header>



    </>
  )
}

export default Navbar