import React from 'react'

function Contact() {
  return (
    <div>
      <>
  <section className="inner-intro bg-1 bg-overlay-black-70">
    <div className="container">
      <div className="row text-center intro-title">
        <div className="col-md-6 text-md-start d-inline-block">
          <h1 className="text-white">Contact us </h1>
        </div>
        <div className="col-md-6 text-md-end float-end">
          <ul className="page-breadcrumb">
            <li>
              <a href="#">
                <i className="fa fa-home" /> Home
              </a>{" "}
              <i className="fa fa-angle-double-right" />
            </li>
            <li>
              <a href="#">pages</a> <i className="fa fa-angle-double-right" />
            </li>
            <li>
              <span>Contact us 01</span>{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 inner-intro */}
  {/*=================================
 contact */}
  <section className="contact page-section-ptb white-bg">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>We’d Love to Hear From You</span>
            <h2>LET'S GET IN TOUCH!</h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="contact-box text-center grey-border">
            <i className="fa fa-map-marker" />
            <h5>Address</h5>
            <p>Sandy Train Station Car Park, Station Road, Sandy, Bedfordshire, SG19 1AW</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="contact-box text-center grey-border">
            <i className="fa fa-phone" />
            <h5>Phone</h5>
            <p>  07555 444087</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 mb-2 mb-sm-0">
          <div className="contact-box text-center grey-border mb-4 mb-sm-0">
            <i className="fa fa-envelope-o" />
            <h5>Email</h5>
            <p> support@website.com</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="contact-box text-center mb-0 grey-border">
            <i className="fa fa-fax" />
            <h5>Fax</h5>
            <p> 07555 444087</p>
          </div>
        </div>
      </div>
      <div className="page-section-ptb">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <div className="gray-form">
              <div
                id="formmessage"
                className="form-notice"
                style={{ display: "none" }}
              >
                Success/Error Message Goes Here
              </div>
              <form
                className="form-horizontal"
                id="contactform"
                role="form"
                method="post"
                action="https://themes.potenzaglobalsolutions.com/html/cardealer/php/contact-form.php"
              >
                <div className="contact-form row">
                  <div className="col-lg-4 col-sm-12">
                    <div className="mb-3">
                      <input
                        id="contactform_name"
                        type="text"
                        placeholder="Name*"
                        className="form-control"
                        name="name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <div className="mb-3">
                      <input
                        id="contactform_email"
                        type="email"
                        placeholder="Email*"
                        className="form-control"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <div className="mb-3">
                      <input
                        id="contactform_phone"
                        type="text"
                        placeholder="Phone*"
                        className="form-control"
                        name="phone"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <textarea
                        id="contactform_message"
                        className="form-control input-message"
                        placeholder="Comment*"
                        rows={7}
                        name="message"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <input
                      type="hidden"
                      name="action"
                      defaultValue="sendEmail"
                    />
                    <button
                      id="submit"
                      name="submit"
                      type="submit"
                      value="Send"
                      className="button red"
                    >
                      Send your message{" "}
                      <i
                        className="fa fa-spinner fa-spin fa-fw btn-loader"
                        style={{ display: "none" }}
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 mt-lg-0 mt-4">
            <div className="opening-hours gray-bg mt-sm-0">
              <h6>opening hours</h6>
              <ul className="list-style-none">
                <li>
                  <strong>Sunday</strong>{" "}
                  <span className="text-red"> closed</span>
                </li>
                <li>
                  <strong>Monday</strong> <span> 9:00 AM to 9:00 PM</span>
                </li>
                <li>
                  <strong>Tuesday </strong> <span> 9:00 AM to 9:00 PM</span>
                </li>
                <li>
                  <strong>Wednesday </strong> <span> 9:00 AM to 9:00 PM</span>
                </li>
                <li>
                  <strong>Thursday </strong> <span> 9:00 AM to 9:00 PM</span>
                </li>
                <li>
                  <strong>Friday </strong> <span> 9:00 AM to 9:00 PM</span>
                </li>
                <li>
                  <strong>Saturday </strong> <span> 9:00 AM to 9:00 PM</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="feature-box-3 grey-border">
            <div className="icon">
              <i className="fa fa-clock-o" />
            </div>
            <div className="content">
              <h6>opening hours </h6>
              <p>
                Voluptatem accusanoremque sed ut perspiciatis unde omnis iste
                natus error sit laudantium, totam rem aperiam.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="feature-box-3 grey-border">
            <div className="icon">
              <i className="fa fa-support" />
            </div>
            <div className="content">
              <h6>Our Support Center </h6>
              <p>
                Iste natus error sit sed ut perspiciatis unde omnis voluptatem
                laudantium, totam rem aperiam.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="feature-box-3 grey-border mb-0">
            <div className="icon">
              <i className="fa fa-info" />
            </div>
            <div className="content">
              <h6>Some Information </h6>
              <p className="mb-0">
                Totam rem aperiam sed ut perspiciatis unde omnis iste natus
                error sit voluptatem laudantium.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 contact */}
  {/*=================================
 contact-map */}
  <section className="contact-map">
    <div className="container-fluid">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3224.017231421863!2d-79.43780268425046!3d36.09306798010035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88532bae09664ccb%3A0xaa6b8f98d3fb8135!2s220+E+Front+St%2C+Burlington%2C+NC+27215%2C+USA!5e0!3m2!1sen!2sin!4v1475045272926"
        allowFullScreen=""
      />
    </div>
  </section>
</>

    </div>
  )
}

export default Contact
