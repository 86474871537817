import React from 'react';

function Home() {
  return (
    <div>
      <>
 
 
  <section className="slider">
    <div
      id="rev_slider_2_1_wrapper"
      className="rev_slider_wrapper fullwidthbanner-container"
      data-alias="car-dealer-03"
      style={{
        margin: "0px auto",
        backgroundColor: "transparent",
        padding: 0,
        marginTop: 0,
        marginBottom: 0
      }}
    >
      {/* START REVOLUTION SLIDER 5.2.6 fullwidth mode */}
      <div
        id="rev_slider_2_1"
        className="rev_slider fullwidthabanner"
        style={{ display: "none" }}
        data-version="5.2.6"
      >
        <ul>
          {" "}
          {/* SLIDE  */}
          <li
            data-index="rs-5"
            data-transition="fade"
            data-slotamount="default"
            data-hideafterloop={0}
            data-hideslideonmobile="off"
            data-easein="default"
            data-easeout="default"
            data-masterspeed="default"
            data-thumb="revolution/assets/100x50_3176d-road-bg.jpg"
            data-rotate={0}
            data-saveperformance="off"
            data-title="Slide"
            data-param1=""
            data-param2=""
            data-param3=""
            data-param4=""
            data-param5=""
            data-param6=""
            data-param7=""
            data-param8=""
            data-param9=""
            data-param10=""
            data-description=""
          >
            {/* MAIN IMAGE */}
            <img
              src="revolution/assets/3176d-road-bg.jpg"
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              className="rev-slidebg"
              data-no-retina=""
            />
            {/* LAYERS */}
            {/* LAYER NR. 1 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-5-layer-6"
              data-x="center"
              data-hoffset=""
              data-y={270}
              data-width="['auto']"
              data-height="['auto']"
              data-transform_idle="o:1;"
              data-transform_in="y:[-100%];z:0;rZ:35deg;sX:1;sY:1;skX:0;skY:0;s:800;e:Power4.easeInOut;"
              data-transform_out="opacity:0;s:300;"
              data-mask_in="x:0px;y:0px;"
              data-start={1400}
              data-splitin="chars"
              data-splitout="none"
              data-responsive_offset="on"
              data-elementdelay="0.05"
              style={{
                zIndex: 5,
                whiteSpace: "nowrap",
                fontSize: 30,
                lineHeight: 30,
                fontWeight: 400,
                color: "rgba(255, 255, 255, 1.00)",
                fontFamily: "Roboto",
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              Welcome to the most stunning{" "}
            </div>
            {/* LAYER NR. 2 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-5-layer-7"
              data-x="center"
              data-hoffset=""
              data-y="center"
              data-voffset={-140}
              data-width="['auto']"
              data-height="['auto']"
              data-transform_idle="o:1;"
              data-transform_in="y:[-100%];z:0;rZ:35deg;sX:1;sY:1;skX:0;skY:0;s:800;e:Power4.easeInOut;"
              data-transform_out="opacity:0;s:300;"
              data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
              data-start={1700}
              data-splitin="chars"
              data-splitout="none"
              data-responsive_offset="on"
              data-elementdelay="0.05"
              style={{
                zIndex: 6,
                whiteSpace: "nowrap",
                fontSize: 70,
                lineHeight: 70,
                fontWeight: 700,
                color: "rgba(255, 255, 255, 1.00)",
                fontFamily: "Roboto",
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              Car dealer website
            </div>
            {/* LAYER NR. 3 */}
            
            {/* LAYER NR. 4 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-5-layer-12"
              data-x="right"
              data-hoffset={70}
              data-y="center"
              data-voffset={135}
              data-width="['none','none','none','none']"
              data-height="['none','none','none','none']"
              data-transform_idle="o:1;"
              data-transform_in="x:-50px;opacity:0;s:800;e:Power2.easeInOut;"
              data-transform_out="opacity:0;s:300;"
              data-start={620}
              data-responsive_offset="on"
              style={{ zIndex: 8 }}
            >
              <img
                src="revolution/assets/4f45e-07-bmw-s2.png"
                alt=""
                data-ww="auto"
                data-hh="auto"
                data-no-retina=""
              />{" "}
            </div>
            {/* LAYER NR. 5 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-5-layer-11"
              data-x={120}
              data-y="center"
              data-voffset={130}
              data-width="['none','none','none','none']"
              data-height="['none','none','none','none']"
              data-transform_idle="o:1;"
              data-transform_in="x:50px;opacity:0;s:800;e:Power2.easeInOut;"
              data-transform_out="opacity:0;s:300;"
              data-start={200}
              data-responsive_offset="on"
              style={{ zIndex: 9 }}
            >
              <img
                src="revolution/assets/e13ec-06-audi-s2.png"
                alt=""
                data-ww="auto"
                data-hh="auto"
                data-no-retina=""
              />{" "}
            </div>
          </li>
          {/* SLIDE  */}
          <li
            data-index="rs-6"
            data-transition="fade"
            data-slotamount="default"
            data-hideafterloop={0}
            data-hideslideonmobile="off"
            data-easein="default"
            data-easeout="default"
            data-masterspeed="default"
            data-thumb="revolution/assets/100x50_3176d-road-bg.jpg"
            data-rotate={0}
            data-saveperformance="off"
            data-title="Slide"
            data-param1=""
            data-param2=""
            data-param3=""
            data-param4=""
            data-param5=""
            data-param6=""
            data-param7=""
            data-param8=""
            data-param9=""
            data-param10=""
            data-description=""
          >
            {/* MAIN IMAGE */}
            <img
              src="revolution/assets/3176d-road-bg.jpg"
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              className="rev-slidebg"
              data-no-retina=""
            />
            {/* LAYERS */}
            {/* LAYER NR. 1 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-6-layer-4"
              data-x={3}
              data-y="center"
              data-voffset={50}
              data-width="['none','none','none','none']"
              data-height="['none','none','none','none']"
              data-transform_idle="o:1;"
              data-transform_in="x:50px;opacity:0;s:1500;e:Power3.easeOut;"
              data-transform_out="opacity:0;s:300;"
              data-start={2060}
              data-responsive_offset="on"
              style={{ zIndex: 5 }}
            >
              <img
                src="revolution/assets/74231-04-audi.png"
                alt=""
                data-ww="auto"
                data-hh="auto"
                data-no-retina=""
              />{" "}
            </div>
            {/* LAYER NR. 2 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-6-layer-5"
              data-x="right"
              data-hoffset={-10}
              data-y="center"
              data-voffset={60}
              data-width="['none','none','none','none']"
              data-height="['none','none','none','none']"
              data-transform_idle="o:1;"
              data-transform_in="x:-50px;opacity:0;s:1500;e:Power3.easeOut;"
              data-transform_out="opacity:0;s:300;"
              data-start={2060}
              data-responsive_offset="on"
              style={{ zIndex: 6 }}
            >
              <img
                src="revolution/assets/35261-05-honda.png"
                alt=""
                data-ww="auto"
                data-hh="auto"
                data-no-retina=""
              />{" "}
            </div>
            {/* LAYER NR. 3 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-6-layer-6"
              data-x="center"
              data-hoffset=""
              data-y={270}
              data-width="['auto']"
              data-height="['auto']"
              data-transform_idle="o:1;"
              data-transform_in="y:[-100%];z:0;rZ:35deg;sX:1;sY:1;skX:0;skY:0;s:300;e:Power4.easeInOut;"
              data-transform_out="opacity:0;s:300;"
              data-mask_in="x:0px;y:0px;"
              data-start={3260}
              data-splitin="chars"
              data-splitout="none"
              data-responsive_offset="on"
              data-elementdelay="0.05"
              style={{
                zIndex: 7,
                whiteSpace: "nowrap",
                fontSize: 30,
                lineHeight: 30,
                fontWeight: 400,
                color: "rgba(255, 255, 255, 1.00)",
                fontFamily: "Roboto",
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              We have everything{" "}
            </div>
            {/* LAYER NR. 4 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-6-layer-7"
              data-x="center"
              data-hoffset=""
              data-y="center"
              data-voffset={-140}
              data-width="['auto']"
              data-height="['auto']"
              data-transform_idle="o:1;"
              data-transform_in="y:[-100%];z:0;rZ:35deg;sX:1;sY:1;skX:0;skY:0;s:300;e:Power4.easeInOut;"
              data-transform_out="opacity:0;s:300;"
              data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
              data-start={4290}
              data-splitin="chars"
              data-splitout="none"
              data-responsive_offset="on"
              data-elementdelay="0.05"
              style={{
                zIndex: 8,
                whiteSpace: "nowrap",
                fontSize: 70,
                lineHeight: 70,
                fontWeight: 700,
                color: "rgba(255, 255, 255, 1.00)",
                fontFamily: "Roboto",
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              Your car needs!{" "}
            </div>
            {/* LAYER NR. 5 */}
            <div
              className="tp-caption button red  tp-resizeme"
              id="slide-6-layer-10"
              data-x="center"
              data-hoffset=""
              data-y="bottom"
              data-voffset={140}
              data-width="['auto']"
              data-height="['auto']"
              data-transform_idle="o:1;"
              data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:300;e:Power0.easeIn;"
              data-style_hover="c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);"
              data-transform_in="y:bottom;s:600;e:Power2.easeInOut;"
              data-transform_out="opacity:0;s:300;"
              data-start="5329.8614501953"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
              data-end="8999.8614501953"
              style={{
                zIndex: 9,
                whiteSpace: "nowrap",
                fontSize: 14,
                lineHeight: 18,
                fontWeight: 400,
                color: "rgba(255, 255, 255, 1.00)",
                fontFamily: "Open Sans",
                textAlign: "center",
                textTransform: "uppercase",
                backgroundColor: "rgba(219, 45, 46, 1.00)",
                padding: "12px 20px 12px 20px",
                borderColor: "rgba(0, 0, 0, 1.00)",
                outline: "none",
                boxShadow: "none",
                boxSizing: "border-box",
                MozBoxSizing: "border-box",
                WebkitBoxSizing: "border-box",
                cursor: "pointer"
              }}
            >
              learn more{" "}
            </div>
            {/* LAYER NR. 6 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-6-layer-3"
              data-x="right"
              data-hoffset={159}
              data-y="center"
              data-voffset={81}
              data-width="['none','none','none','none']"
              data-height="['none','none','none','none']"
              data-transform_idle="o:1;"
              data-transform_in="x:-50px;opacity:0;s:1500;e:Power3.easeOut;"
              data-transform_out="opacity:0;s:300;"
              data-start={1220}
              data-responsive_offset="on"
              style={{ zIndex: 10 }}
            >
              <img
                src="revolution/assets/ec416-03-huydai.png"
                alt=""
                data-ww="auto"
                data-hh="auto"
                data-no-retina=""
              />{" "}
            </div>
            {/* LAYER NR. 7 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-6-layer-2"
              data-x={202}
              data-y="center"
              data-voffset={80}
              data-width="['none','none','none','none']"
              data-height="['none','none','none','none']"
              data-transform_idle="o:1;"
              data-transform_in="x:50px;opacity:0;s:1500;e:Power3.easeOut;"
              data-transform_out="opacity:0;s:300;"
              data-start={1200}
              data-responsive_offset="on"
              style={{ zIndex: 11 }}
            >
              <img
                src="revolution/assets/1fa45-02-bmw.png"
                alt=""
                data-ww="auto"
                data-hh="auto"
                data-no-retina=""
              />{" "}
            </div>
            {/* LAYER NR. 8 */}
            <div
              className="tp-caption   tp-resizeme"
              id="slide-6-layer-1"
              data-x="center"
              data-hoffset=""
              data-y="center"
              data-voffset={100}
              data-width="['none','none','none','none']"
              data-height="['none','none','none','none']"
              data-transform_idle="o:1;"
              data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:670;e:Power3.easeInOut;"
              data-transform_out="opacity:0;s:300;"
              data-start={500}
              data-responsive_offset="on"
              style={{ zIndex: 12 }}
            >
              <img
                src="revolution/assets/95515-o1-kia.png"
                alt=""
                data-ww="auto"
                data-hh="auto"
                data-no-retina=""
              />{" "}
            </div>
          </li>
        </ul>
        <div
          className="tp-bannertimer tp-bottom"
          style={{ visibility: "hidden !important" }}
        />{" "}
      </div>
    </div>
  </section>
  {/*=================================
  rev slider */}
  {/*=================================
 welcome */}
  <section className="welcome-block objects-car page-section-ptb white-bg">
    <div className="objects-left left">
      <img className="img-fluid objects-1" src="images/objects/01.jpg" alt="" />
    </div>
    <div className="objects-right right">
      <img className="img-fluid objects-2" src="images/objects/02.jpg" alt="" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>Welcome to our website</span>
            <h2>Dealeractive</h2>
            <div className="separator" />
            <p>
              Car Dealer is the best premium HTML5 Template. We provide
              everything you need to build an{" "}
              <strong>Amazing dealership website</strong> developed especially
              for car sellers, dealers or auto motor retailers. You can use this
              template for creating website based on any framework and any
              language.
            </p>{" "}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="feature-box text-center">
            <div className="icon">
              <i className="glyph-icon flaticon-beetle" />
            </div>
            <div className="content">
              <h6>All brands</h6>
              <p>
                Galley simply dummy text lorem Ipsum is of the printin k a of
                type and
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="feature-box text-center">
            <div className="icon">
              <i className="glyph-icon flaticon-interface-1" />
            </div>
            <div className="content">
              <h6>Free Support</h6>
              <p>
                Text of the printin lorem ipsum the is simply k a type text and
                galley of
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="feature-box text-center">
            <div className="icon">
              <i className="glyph-icon flaticon-key" />
            </div>
            <div className="content">
              <h6>Dealership</h6>
              <p>
                Printin k a of type and lorem Ipsum is simply dummy text of the
                galley{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="feature-box text-center">
            <div className="icon">
              <i className="glyph-icon flaticon-wallet" />
            </div>
            <div className="content">
              <h6>affordable</h6>
              <p>
                The printin k a galley Lorem Ipsum is type and simply dummy text
                of
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="halp-call text-center">
            <img className="img-fluid" src="images/team/01.jpg" alt="" />
            <span>Have any question ?</span>
            <h2 className="text-red">07555 444087</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 welcome */}
  {/*=================================
 feature-car */}
  <section className="feature-car bg-2 bg-overlay-black-70 page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span className="text-white">Check out our recent cars</span>
            <h2 className="text-white">Feature Car </h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div
            className="owl-carousel owl-theme"
            data-nav-arrow="true"
            data-items={4}
            data-md-items={4}
            data-sm-items={2}
            data-xs-items={1}
            data-space={20}
          >
            <div className="item">
              <div className="car-item text-center">
                <div className="car-image">
                  <img className="img-fluid" src="images/car/01.jpg" alt="" />
                  <div className="car-overlay-banner">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa fa-link" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-dashboard" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="car-list">
                  <ul className="list-inline">
                    <li>
                      <i className="fa fa-registered" /> 2021
                    </li>
                    <li>
                      <i className="fa fa-cog" /> Manual{" "}
                    </li>
                    <li>
                      <i className="fa fa-dashboard" /> 6,000 mi
                    </li>
                  </ul>
                </div>
                <div className="car-content">
                  <div className="star">
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star-o orange-color" />
                  </div>
                  <a href="#">Acura Rsx</a>
                  <div className="separator" />
                  <div className="price">
                    <span className="old-price">£35,568</span>
                    <span className="new-price">£32,698 </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="car-item text-center">
                <div className="car-image">
                  <img className="img-fluid" src="images/car/02.jpg" alt="" />
                  <div className="car-overlay-banner">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa fa-link" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-dashboard" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="car-list">
                  <ul className="list-inline">
                    <li>
                      <i className="fa fa-registered" /> 2021
                    </li>
                    <li>
                      <i className="fa fa-cog" /> Manual{" "}
                    </li>
                    <li>
                      <i className="fa fa-dashboard" /> 6,000 mi
                    </li>
                  </ul>
                </div>
                <div className="car-content">
                  <div className="star">
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star-o orange-color" />
                  </div>
                  <a href="#">Lexus GS 450h</a>
                  <div className="separator" />
                  <div className="price">
                    <span className="old-price">£35,568</span>
                    <span className="new-price">£32,698 </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="car-item text-center">
                <div className="car-image">
                  <img className="img-fluid" src="images/car/03.jpg" alt="" />
                  <div className="car-overlay-banner">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa fa-link" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-dashboard" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="car-list">
                  <ul className="list-inline">
                    <li>
                      <i className="fa fa-registered" /> 2021
                    </li>
                    <li>
                      <i className="fa fa-cog" /> Manual{" "}
                    </li>
                    <li>
                      <i className="fa fa-dashboard" /> 6,000 mi
                    </li>
                  </ul>
                </div>
                <div className="car-content">
                  <div className="star">
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star-o orange-color" />
                  </div>
                  <a href="#">GTA 5 Lowriders DLC</a>
                  <div className="separator" />
                  <div className="price">
                    <span className="old-price">£35,568</span>
                    <span className="new-price">£32,698 </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="car-item text-center">
                <div className="car-image">
                  <img className="img-fluid" src="images/car/04.jpg" alt="" />
                  <div className="car-overlay-banner">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa fa-link" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-dashboard" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="car-list">
                  <ul className="list-inline">
                    <li>
                      <i className="fa fa-registered" /> 2021
                    </li>
                    <li>
                      <i className="fa fa-cog" /> Manual{" "}
                    </li>
                    <li>
                      <i className="fa fa-dashboard" /> 6,000 mi
                    </li>
                  </ul>
                </div>
                <div className="car-content">
                  <div className="star">
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star-o orange-color" />
                  </div>
                  <a href="#">Toyota avalon hybrid </a>
                  <div className="separator" />
                  <div className="price">
                    <span className="old-price">£35,568</span>
                    <span className="new-price">£32,698 </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="car-item text-center">
                <div className="car-image">
                  <img className="img-fluid" src="images/car/05.jpg" alt="" />
                  <div className="car-overlay-banner">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa fa-link" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-dashboard" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="car-list">
                  <ul className="list-inline">
                    <li>
                      <i className="fa fa-registered" /> 2021
                    </li>
                    <li>
                      <i className="fa fa-cog" /> Manual{" "}
                    </li>
                    <li>
                      <i className="fa fa-dashboard" /> 6,000 mi
                    </li>
                  </ul>
                </div>
                <div className="car-content">
                  <div className="star">
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star-o orange-color" />
                  </div>
                  <a href="#">Hyundai santa fe sport </a>
                  <div className="separator" />
                  <div className="price">
                    <span className="old-price">£35,568</span>
                    <span className="new-price">£32,698 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 feature-car */}
  {/*=================================
 custom block */}
  <section className="bg-7">
    <div className="container-fluid p-0">
      <div className="row g-0">
        <div className="col-lg-6 col-md-12"></div>
        <div className="col-lg-6 col-md-12 gray-bg text-center">
          <div className="custom-block-1">
            <h2>Kar Zone</h2>
            <span>Get the Porsche You always Wanted to purchase </span>
            <strong className="text-red"></strong>
            {/* <span>  </span>
            <p>Limited time Offer!</p>
            <a href="#"> read more </a> */}
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 custom block */}
  {/*=================================
 latest news */}
  <section className="latest-blog objects-car white-bg page page-section-ptb">
    <div className="objects-left">
      <img className="img-fluid objects-1" src="images/objects/03.jpg" alt="" />
    </div>
    <div className="objects-right">
      <img className="img-fluid objects-2" src="images/objects/04.jpg" alt="" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>Read our latest news</span>
            <h2>Latest News </h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="blog-1">
        <div className="row">
          <div className="col-md-6">
            <img className="img-fluid" src="images/blog/01.jpg" alt="" />
          </div>
          <div className="col-md-6">
            <div className="blog-content">
              <a className="link" href="#">
                Porsche 911 is text of the printin a galley of type and bled it
                to make a type specimen book.{" "}
              </a>
              <span className="uppercase">
                November 29, 2021 |{" "}
                <strong className="text-red">post by john doe </strong>
              </span>
              <p>
                Sed do eiusmod tempor lorem ipsum dolor sit amet, consectetur
                adipisicing elit, incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam.
              </p>
              <p>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa{" "}
              </p>
              <a className="button border" href="#">
                Read more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 latest news */}
  {/*=================================
 play-video */}
  <section className="play-video popup-gallery">
    <div className="play-video-bg bg-3 bg-overlay-black-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 text-center">
            <h3 className="text-white">
              Want to know more about us? Play our promotional video now!
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-md-12">
          <div className="video-info text-center">
            <img
              className="img-fluid center-block"
              src="images/car/24.jpg"
              alt=""
            />
            <a
              className="popup-youtube"
              href="https://www.youtube.com/watch?v=Xd0Ok-MkqoE"
            >
              {" "}
              <i className="fa fa-play" />{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 play-video */}
  {/*=================================
 Counter */}
  <section className="counter counter-style-1 light page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-sm-6 text-center">
          <div className="counter-block">
            <i className="glyph-icon flaticon-beetle" />
            <h6 className="text-black">Vehicles In Stock</h6>
            <b className="timer" data-to={3968} data-speed={10000} />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 text-center">
          <div className="counter-block">
            <i className="glyph-icon flaticon-interface" />
            <h6 className="text-black">Dealer Reviews</h6>
            <b className="timer" data-to={5568} data-speed={10000} />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 text-center">
          <div className="counter-block mb-4 mb-sm-0">
            <i className="glyph-icon flaticon-circle" />
            <h6 className="text-black">Happy Customer</h6>
            <b className="timer" data-to={8908} data-speed={10000} />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 text-center">
          <div className="counter-block mb-0">
            <i className="glyph-icon flaticon-cup" />
            <h6 className="text-black">Awards</h6>
            <b className="timer" data-to={9968} data-speed={10000} />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 Counter */}
  <hr className="gray" />
  {/*=================================
 testimonial */}
  <section className="testimonial-1 white-bg page page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>What Our Happy Clients say about us</span>
            <h2>our Testimonial </h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div
            className="owl-carousel"
            data-items={3}
            data-md-items={3}
            data-sm-items={2}
            data-xs-items={1}
            data-space={20}
          >
            <div className="item">
              <div className="testimonial-block text-center">
                <div className="testimonial-image">
                  <img
                    className="img-fluid"
                    src="images/testimonial/01.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-avtar">
                    <img
                      className="img-fluid"
                      src="images/team/01.jpg"
                      alt=""
                    />
                    <h6>Alice Williams</h6>
                    <span>Auto Dealer</span>
                  </div>
                  <div className="testimonial-content">
                    <p>
                      It has survived not only five centuries. lorem Ipsum is
                      simply dummy text of the printin a galley of type and bled
                      it to make a type specimen book.
                    </p>
                    <i className="fa fa-quote-right" />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-block text-center">
                <div className="testimonial-image">
                  <img
                    className="img-fluid"
                    src="images/testimonial/02.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-avtar">
                    <img
                      className="img-fluid"
                      src="images/team/02.jpg"
                      alt=""
                    />
                    <h6>Michael Bean</h6>
                    <span>Car Dealer</span>
                  </div>
                  <div className="testimonial-content">
                    <p>
                      A galley of type and bled it to make a type specimen book.
                      Ipsum is simply dummy text of the printin It has survived
                      not only five centuries.
                    </p>
                    <i className="fa fa-quote-right" />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-block text-center">
                <div className="testimonial-image">
                  <img
                    className="img-fluid"
                    src="images/testimonial/03.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-avtar">
                    <img
                      className="img-fluid"
                      src="images/team/03.jpg"
                      alt=""
                    />
                    <h6>Felica Queen</h6>
                    <span>Auto Dealer</span>
                  </div>
                  <div className="testimonial-content">
                    <p>
                      Text of the printin a galley of type and bled it to a type
                      specimen book. It has survived not only five centuries
                      make Lorem Ipsum is simply dummy.
                    </p>
                    <i className="fa fa-quote-right" />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-block text-center">
                <div className="testimonial-image">
                  <img
                    className="img-fluid"
                    src="images/testimonial/04.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-avtar">
                    <img
                      className="img-fluid"
                      src="images/team/04.jpg"
                      alt=""
                    />
                    <h6>Sara Lisbon</h6>
                    <span>Customer</span>
                  </div>
                  <div className="testimonial-content">
                    <p>
                      Printin a galley of type and bled It has survived not
                      lorem Ipsum is simply dummy text of the it to make a type
                      specimen book only five centuries.
                    </p>
                    <i className="fa fa-quote-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 testimonial */}
  {/*=================================
 footer */}
 
</>

    </div>
  )
}

export default Home
