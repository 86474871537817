import React from 'react'

function Cardetails() {
  return (
    <div>
        <>
  <section className="inner-intro bg-1 bg-overlay-black-70">
    <div className="container">
      <div className="row text-center intro-title">
        <div className="col-md-6 text-md-start d-inline-block">
          <h1 className="text-white">Hyundai Santa Fe </h1>
        </div>
        <div className="col-md-6 text-md-end float-end">
          <ul className="page-breadcrumb">
            <li>
              <a href="#">
                <i className="fa fa-home" /> Home
              </a>{" "}
              <i className="fa fa-angle-double-right" />
            </li>
            <li>
              <a href="#">pages</a> <i className="fa fa-angle-double-right" />
            </li>
            <li>
              <span>details 01</span>{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 inner-intro */}
  {/*=================================
car-details  */}
  <section className="car-details page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-md-9">
          <h3>Hyundai Santa Fe </h3>
          <p>
            Temporibus possimus quasi beatae, You will begin to realize why,
            consectetur adipisicing elit. aspernatur nemo maiores.
          </p>
        </div>
        <div className="col-md-3">
          <div className="car-price text-lg-end">
            <strong>$ 69,995</strong>
            <span>Plus Taxes &amp; Licensing</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="details-nav">
            <ul>
              <li>
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="fa fa-question-circle" />
                  Request More Info
                </a>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Request More Info
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body">
                        <div
                          id="rmi_notice"
                          className="form-notice"
                          style={{ display: "none" }}
                        />
                        <p className="sub-title">
                          Please fill out the information below and one of our
                          representatives will contact you regarding your more
                          information request.{" "}
                        </p>
                        <form
                          className="gray-form reset_css"
                          id="rmi_form"
                          action="https://themes.potenzaglobalsolutions.com/html/cardealer/post"
                        >
                          <input
                            type="hidden"
                            name="action"
                            defaultValue="request_more_info"
                          />
                          <div className="alrt">
                            <span className="alrt" />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Name*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="rmi_name"
                              id="rmi_name"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Email address*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="rmi_email"
                              id="rmi_email"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Phone*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="rmi_phone"
                              name="rmi_phone"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Message</label>
                            <textarea
                              className="form-control"
                              name="rmi_message"
                              id="rmi_message"
                              defaultValue={""}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label pe-3">
                              Preferred Contact*
                            </label>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                              />
                              <label
                                className="form-check-label p-0 text-uppercase"
                                htmlFor="flexRadioDefault1"
                              >
                                Email
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                defaultChecked=""
                              />
                              <label
                                className="form-check-label p-0 text-uppercase"
                                htmlFor="flexRadioDefault2"
                              >
                                Phone
                              </label>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div id="recaptcha1" />
                          </div>
                          <div>
                            <a
                              className="button red"
                              id="request_more_info_submit"
                            >
                              Submit{" "}
                              <i
                                className="fa fa-spinner fa-spin fa-fw btn-loader"
                                style={{ display: "none" }}
                              />
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  data-whatever="@mdo"
                  href="#"
                  className="css_btn"
                >
                  <i className="fa fa-dashboard" />
                  Schedule Test Drive
                </a>
                <div
                  className="modal fade"
                  id="exampleModal2"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel2">
                          Schedule Test Drive
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body">
                        <div
                          id="std_notice"
                          className="form-notice"
                          style={{ display: "none" }}
                        />
                        <p className="sub-title">
                          Complete this form to request a test drive of your
                          favorite car. Our Sales Advisor will contact you
                          promptly to confirm your appointment.{" "}
                        </p>
                        <form
                          className="gray-form reset_css"
                          id="std_form"
                          action="https://themes.potenzaglobalsolutions.com/html/cardealer/post"
                        >
                          <input
                            type="hidden"
                            name="action"
                            defaultValue="schedule_test_drive"
                          />
                          <div className="mb-3">
                            <label className="form-label">Name*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="std_firstname"
                              name="std_firstname"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Email address*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="std_email"
                              name="std_email"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Phone*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="std_phone"
                              name="std_phone"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Preferred Day*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="std_day"
                              name="std_day"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Preferred Time*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="std_time"
                              name="std_time"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label pe-3">
                              Preferred Contact*
                            </label>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault01"
                              />
                              <label
                                className="form-check-label p-0 text-uppercase"
                                htmlFor="flexRadioDefault01"
                              >
                                Email
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault02"
                                defaultChecked=""
                              />
                              <label
                                className="form-check-label p-0 text-uppercase"
                                htmlFor="flexRadioDefault02"
                              >
                                Phone
                              </label>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div id="recaptcha2" />
                          </div>
                          <div>
                            <a
                              className="button red"
                              id="schedule_test_drive_submit"
                            >
                              Schedule Now{" "}
                              <i
                                className="fa fa-spinner fa-spin fa-fw btn-loader"
                                style={{ display: "none" }}
                              />
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                  data-whatever="@mdo"
                  href="#"
                  className="css_btn"
                >
                  <i className="fa fa-tag" />
                  Make an Offer
                </a>
                <div
                  className="modal fade"
                  id="exampleModal3"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                          Make an Offer
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body">
                        <div
                          id="mao_notice"
                          className="form-notice"
                          style={{ display: "none" }}
                        />
                        <form
                          className="gray-form reset_css"
                          action="https://themes.potenzaglobalsolutions.com/html/cardealer/post"
                          id="mao_form"
                        >
                          <input
                            type="hidden"
                            name="action"
                            defaultValue="make_an_offer"
                          />
                          <div className="mb-3">
                            <label className="form-label">Name*</label>
                            <input
                              type="text"
                              id="mao_name"
                              name="mao_name"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Email address*</label>
                            <input
                              type="text"
                              id="mao_email"
                              name="mao_email"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Phone*</label>
                            <input
                              type="text"
                              id="mao_phone"
                              name="mao_phone"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Offered Price*</label>
                            <input
                              type="text"
                              id="mao_price"
                              name="mao_price"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Financing Required*
                            </label>
                            <div className="selected-box">
                              <select
                                className="selectpicker"
                                id="mao_financing"
                                name="mao_financing"
                              >
                                <option value="Yes">Yes </option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              additional Comments/Conditions*
                            </label>
                            <textarea
                              className="form-control input-message"
                              rows={4}
                              id="mao_comments"
                              name="mao_comments"
                              defaultValue={""}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label pe-3">
                              Preferred Contact*
                            </label>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault001"
                              />
                              <label
                                className="form-check-label p-0 text-uppercase"
                                htmlFor="flexRadioDefault001"
                              >
                                Email
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault002"
                                defaultChecked=""
                              />
                              <label
                                className="form-check-label p-0 text-uppercase"
                                htmlFor="flexRadioDefault002"
                              >
                                Phone
                              </label>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div id="recaptcha3" />
                          </div>
                          <div>
                            <a className="button red" id="make_an_offer_submit">
                              Submit{" "}
                              <i
                                className="fa fa-spinner fa-spin fa-fw btn-loader"
                                style={{ display: "none" }}
                              />
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal4"
                  data-whatever="@mdo"
                  href="#"
                  className="css_btn"
                >
                  <i className="fa fa-envelope" />
                  Email to a Friend
                </a>
                <div
                  className="modal fade"
                  id="exampleModal4"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel4">
                          Email to a Friend
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body">
                        <div
                          id="etf_notice"
                          className="form-notice"
                          style={{ display: "none" }}
                        />
                        <form
                          className="gray-form reset_css"
                          action="https://themes.potenzaglobalsolutions.com/html/cardealer/post"
                          id="etf_form"
                        >
                          <input
                            type="hidden"
                            name="action"
                            defaultValue="email_to_friend"
                          />
                          <div>
                            <span style={{ color: "red" }} className="sp" />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Name*</label>
                            <input
                              name="etf_name"
                              type="text"
                              id="etf_name"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Email address*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="etf_email"
                              name="etf_email"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Friends Email*</label>
                            <input
                              type="Email"
                              className="form-control"
                              id="etf_fmail"
                              name="etf_fmail"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Message to friend*
                            </label>
                            <textarea
                              className="form-control input-message"
                              id="etf_fmessage"
                              name="etf_fmessage"
                              rows={4}
                              defaultValue={""}
                            />
                          </div>
                          <div className="mb-3">
                            <div id="recaptcha4" />
                          </div>
                          <div>
                            <a
                              className="button red"
                              id="email_to_friend_submit"
                            >
                              Submit{" "}
                              <i
                                className="fa fa-spinner fa-spin fa-fw btn-loader"
                                style={{ display: "none" }}
                              />
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal5"
                  data-whatever="@mdo"
                  href="#"
                  className="css_btn"
                >
                  <i className="fa fa-arrow-circle-o-down" />
                  Trade-In Appraisal
                </a>
                <div
                  className="modal fade bd-example-modal-lg"
                  id="exampleModal5"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel5">
                          Trade-In Appraisal
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body">
                        <div
                          id="tia_notice"
                          className="form-notice"
                          style={{ display: "none" }}
                        />
                        <form
                          className="gray-form reset_css"
                          action="https://themes.potenzaglobalsolutions.com/html/cardealer/post"
                          id="tia_form"
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="hidden"
                                name="action"
                                defaultValue="trade_in_appraisal"
                              />
                              <div className="row">
                                <div className="col-md-12">
                                  <h6>Contact Information </h6>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      First Name*
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_firstname"
                                      id="tia_firstname"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Last Name *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_lastname"
                                      id="tia_lastname"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Work Phone*
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_workphone"
                                      id="tia_workphone"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">Phone*</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_phone"
                                      id="tia_phone"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">Email*</label>
                                    <input
                                      type="Email"
                                      className="form-control"
                                      name="tia_email"
                                      id="tia_email"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label pe-3">
                                      Preferred Contact*
                                    </label>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault0001"
                                      />
                                      <label
                                        className="form-check-label p-0 text-uppercase"
                                        htmlFor="flexRadioDefault0001"
                                      >
                                        Email
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault0002"
                                        defaultChecked=""
                                      />
                                      <label
                                        className="form-check-label p-0 text-uppercase"
                                        htmlFor="flexRadioDefault0002"
                                      >
                                        Phone
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Comments*
                                    </label>
                                    <textarea
                                      className="form-control input-message"
                                      rows={4}
                                      name="tia_comments"
                                      id="tia_comments"
                                      defaultValue={""}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 check-options">
                              <div className="row">
                                <div className="col-md-12">
                                  <h6>Options</h6>
                                </div>
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck01"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck01"
                                        >
                                          Navigation
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck02"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck02"
                                        >
                                          Sunroof
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck03"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck03"
                                        >
                                          Leather
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck04"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck04"
                                        >
                                          Air conditioning
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck05"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck05"
                                        >
                                          Power Windows
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck06"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck06"
                                        >
                                          Power Locks
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck07"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck07"
                                        >
                                          Power Seats
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck08"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck08"
                                        >
                                          Cruise Control
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck09"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck09"
                                        >
                                          Cassette
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck10"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck10"
                                        >
                                          DVD Player
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck11"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck11"
                                        >
                                          Alloy Wheels
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck12"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck12"
                                        >
                                          Satellite Radio
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck13"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck13"
                                        >
                                          CD Player / Changer
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue=""
                                          id="invalidCheck14"
                                          required=""
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="invalidCheck14"
                                        >
                                          AM/FM Stereo
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <h6>Vehicle Information </h6>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">Year*</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_year"
                                      id="tia_year"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">Make*</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_make"
                                      id="tia_make"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">Model*</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_model"
                                      id="tia_model"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Exterior Colour*
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_colour"
                                      id="tia_colour"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">VIN*</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_vin"
                                      id="tia_vin"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Kilometres *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_kilometers"
                                      id="tia_kilometers"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Engine *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tia_engine"
                                      id="tia_engine"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">Doors </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_doors"
                                        id="tia_doors"
                                      >
                                        <option value={2}>2 </option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Transmission{" "}
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_transmission"
                                        id="tia_transmission"
                                      >
                                        <option value="Automatic">
                                          Automatic
                                        </option>
                                        <option value="Manual">Manual</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Drivetrain
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_drivetrain"
                                        id="tia_drivetrain"
                                      >
                                        <option value="AWD">AWD</option>
                                        <option value="2WD">2WD</option>
                                        <option value="4WD">4WD</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <h6>Vehicle Rating </h6>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Body (dents, dings, rust, rot, damage){" "}
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_vehicle_rating_body"
                                        id="tia_vehicle_rating_body"
                                      >
                                        <option value={1}>1 </option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10 best</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Tires (tread wear, mismatched)
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_vehicle_rating_tires"
                                        id="tia_vehicle_rating_tires"
                                      >
                                        <option value={1}>1 </option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10 best</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Engine (running condition, burns oil,
                                      knocking)
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_vehicle_rating_engine"
                                        id="tia_vehicle_rating_engine"
                                      >
                                        <option value={1}>1 </option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10 best</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Transmission / Clutch (slipping, hard
                                      shift, grinds){" "}
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_vehicle_rating_clutch"
                                        id="tia_vehicle_rating_clutch"
                                      >
                                        <option value={1}>1 </option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10 best</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Glass (chips, cracks, pitted){" "}
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_vehicle_rating_glass"
                                        id="tia_vehicle_rating_glass"
                                      >
                                        <option value={1}>1 </option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10 best</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Interior (rips, tears, burns, faded/worn){" "}
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_vehicle_rating_interior"
                                        id="tia_vehicle_rating_interior"
                                      >
                                        <option value={1}>1 </option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10 best</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Exhaust (rusted, leaking, noisy){" "}
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_vehicle_rating_exhaust"
                                        id="tia_vehicle_rating_exhaust"
                                      >
                                        <option value={1}>1 </option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10 best</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <h6>VEHICLE HISTORY </h6>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Was it ever a lease or rental return?{" "}
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_vehical_info_1"
                                        id="tia_vehical_info_1"
                                      >
                                        <option value="yes">yes </option>
                                        <option value="no">No</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Is the odometer operational and accurate?{" "}
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_vehical_info_2"
                                        id="tia_vehical_info_2"
                                      >
                                        <option value="yes">yes </option>
                                        <option value="no">No</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Detailed service records available?
                                    </label>
                                    <div className="selected-box">
                                      <select
                                        className="selectpicker"
                                        name="tia_vehical_info_3"
                                        id="tia_vehical_info_3"
                                      >
                                        <option value="yes">yes </option>
                                        <option value="no">No</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <h6>Title History </h6>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Is there a lienholder?{" "}
                                    </label>
                                    <input
                                      type="Email"
                                      className="form-control"
                                      name="tia_lineholder_email"
                                      id="tia_lineholder_email"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Who holds this title?{" "}
                                    </label>
                                    <input
                                      type="Email"
                                      className="form-control"
                                      name="tia_titleholder_email"
                                      id="tia_titleholder_email"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 vehicle-assessment">
                              <h6>Vehicle Assessment </h6>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Does all equipment and accessories work
                                  correctly?
                                </label>
                                <textarea
                                  className="form-control input-message"
                                  rows={4}
                                  name="tia_textarea_1"
                                  id="tia_textarea1"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Did you buy the vehicle new?{" "}
                                </label>
                                <textarea
                                  className="form-control input-message"
                                  rows={4}
                                  name="tia_textarea_2"
                                  id="tia_textarea2"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Has the vehicle ever been in any accidents?{" "}
                                </label>
                                <textarea
                                  className="form-control input-message"
                                  rows={4}
                                  name="tia_textarea_3"
                                  id="tia_textarea3"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Is there existing damage on the vehicle?
                                  Where?{" "}
                                </label>
                                <textarea
                                  className="form-control input-message"
                                  rows={4}
                                  name="tia_textarea_4"
                                  id="tia_textarea4"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Has the vehicle ever had paint work performed?{" "}
                                </label>
                                <textarea
                                  className="form-control input-message"
                                  rows={4}
                                  name="tia_textarea_5"
                                  id="tia_textarea5"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Is the title designated 'Salvage' or
                                  'Reconstructed'?{" "}
                                </label>
                                <textarea
                                  className="form-control input-message"
                                  rows={4}
                                  name="tia_textarea_6"
                                  id="tia_textarea6"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3 captcha">
                                <div id="recaptcha5" />
                              </div>
                              <div>
                                <a
                                  className="button red"
                                  id="trade_in_appraisal_submit"
                                >
                                  Submit{" "}
                                  <i
                                    className="fa fa-spinner fa-spin fa-fw btn-loader"
                                    style={{ display: "none" }}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a href="javascript:window.print()">
                  <i className="fa fa-print" />
                  Print this page
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="slider">
            <div className="slider slider-for detail-big-car-gallery">
              <img
                className="img-fluid"
                src="images/detail/big/01.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/big/02.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/big/03.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/big/04.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/big/05.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/big/06.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/big/07.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/big/08.jpg"
                alt=""
              />
            </div>
            <div className="slider slider-nav">
              <img
                className="img-fluid"
                src="images/detail/thum/01.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/thum/02.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/thum/03.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/thum/04.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/thum/05.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/thum/06.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/thum/07.jpg"
                alt=""
              />
              <img
                className="img-fluid"
                src="images/detail/thum/08.jpg"
                alt=""
              />
            </div>
          </div>
          <div id="tabs">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item icon-diamond" role="presentation">
                <button
                  className="nav-link active"
                  id="general-information-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#general-information"
                  type="button"
                  role="tab"
                  aria-controls="general-information"
                  aria-selected="true"
                >
                  General Information
                </button>
              </li>
              <li className="nav-item icon-list" role="presentation">
                <button
                  className="nav-link"
                  id="features-options-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#features-options"
                  type="button"
                  role="tab"
                  aria-controls="features-options"
                  aria-selected="false"
                >
                  Features &amp; Options
                </button>
              </li>
              <li className="nav-item icon-equalizer" role="presentation">
                <button
                  className="nav-link "
                  id="vehicle-overview-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#vehicle-overview"
                  type="button"
                  role="tab"
                  aria-controls="vehicle-overview"
                  aria-selected="false"
                >
                  Vehicle Overview
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="general-information"
                role="tabpanel"
                aria-labelledby="general-information-tab"
              >
                <h6>consectetur adipisicing elit</h6>
                <p>
                  Temporibus possimus quasi beatae, consectetur adipisicing
                  elit. Obcaecati unde molestias sunt officiis aliquid sapiente,
                  numquam, porro perspiciatis neque voluptatem sint hic quam
                  eveniet ad adipisci laudantium corporis ipsam ea!
                  <br />
                  <br />
                  Consectetur adipisicing elit. Dicta, amet quia ad debitis
                  fugiat voluptatem neque dolores tempora iste saepe cupiditate,
                  molestiae iure voluptatibus est beatae? Culpa, illo a You will
                  begin to realize why, consectetur adipisicing elit. Commodi,
                  doloribus, earum modi consectetur molestias asperiores sequi
                  ipsam neque error itaque veniam culpa eligendi similique
                  ducimus nulla, blanditiis, perspiciatis atque saepe!
                  veritatis.
                  <br />
                  <br />
                  Adipisicing consectetur elit. Dicta, amet quia ad debitis
                  fugiat voluptatem neque dolores tempora iste saepe cupiditate,
                  molestiae iure voluptatibus est beatae? Culpa, illo a You will
                  begin to realize why, consectetur adipisicing elit. Commodi,
                  doloribus, earum modi consectetur molestias asperiores.
                  <br />
                  <br />
                  Voluptatem adipisicing elit. Dicta, amet quia ad debitis
                  fugiat neque dolores tempora iste saepe cupiditate, molestiae
                  iure voluptatibus est beatae? Culpa, illo a You will begin to
                  realize why, consectetur adipisicing elit. Commodi, You will
                  begin to realize why, consectetur adipisicing elit. Laudantium
                  nisi eaque maxime totam, iusto accusantium esse placeat rem at
                  temporibus minus architecto ipsum eveniet. Delectus cum sunt,
                  ea cumque quas! doloribus, earum modi consectetur molestias
                  asperiores sequi ipsam neque error itaque veniam culpa
                  eligendi similique ducimus nulla, blanditiis, perspiciatis
                  atque saepe! veritatis.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="features-options"
                role="tabpanel"
                aria-labelledby="features-options-tab"
              >
                <h6>consectetur adipisicing elit</h6>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row"> Air conditioning</th>
                      <td>Mark</td>
                    </tr>
                    <tr>
                      <th scope="row"> Alloy Wheels</th>
                      <td>Jacob</td>
                    </tr>
                    <tr>
                      <th scope="row"> Anti-Lock Brakes (ABS)</th>
                      <td>Larry</td>
                    </tr>
                    <tr>
                      <th scope="row"> Anti-Theft</th>
                      <td>Larry</td>
                    </tr>
                    <tr>
                      <th scope="row">Anti-Starter</th>
                      <td>Larry</td>
                    </tr>
                    <tr>
                      <th scope="row">Alloy Wheels</th>
                      <td>Larry</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="tab-pane fade"
                id="vehicle-overview"
                role="tabpanel"
                aria-labelledby="vehicle-overview-tab"
              >
                <h6>consectetur adipisicing elit</h6>
                <p>
                  Temporibus possimus quasi beatae, consectetur adipisicing
                  elit. Obcaecati unde molestias sunt officiis aliquid sapiente,
                  numquam, porro perspiciatis neque voluptatem sint hic quam
                  eveniet ad adipisci laudantium corporis ipsam ea!
                  <br />
                  <br />
                  Consectetur adipisicing elit. Dicta, amet quia ad debitis
                  fugiat voluptatem neque dolores tempora iste saepe cupiditate,
                  molestiae iure voluptatibus est beatae? Culpa, illo a You will
                  begin to realize why, consectetur adipisicing elit. Commodi,
                  doloribus, earum modi consectetur molestias asperiores sequi
                  ipsam neque error itaque veniam culpa eligendi similique
                  ducimus nulla, blanditiis, perspiciatis atque saepe!
                  veritatis.
                  <br />
                  <br />
                  Adipisicing consectetur elit. Dicta, amet quia ad debitis
                  fugiat voluptatem neque dolores tempora iste saepe cupiditate,
                  molestiae iure voluptatibus est beatae? Culpa, illo a You will
                  begin to realize why, consectetur adipisicing elit. Commodi,
                  doloribus, earum modi consectetur molestias asperiores.
                  <br />
                  <br />
                  Voluptatem adipisicing elit. Dicta, amet quia ad debitis
                  fugiat neque dolores tempora iste saepe cupiditate, molestiae
                  iure voluptatibus est beatae? Culpa, illo a You will begin to
                  realize why, consectetur adipisicing elit. Commodi, You will
                  begin to realize why, consectetur adipisicing elit. Laudantium
                  nisi eaque maxime totam, iusto accusantium esse placeat rem at
                  temporibus minus architecto ipsum eveniet. Delectus cum sunt,
                  ea cumque quas! doloribus, earum modi consectetur molestias
                  asperiores sequi ipsam neque error itaque veniam culpa
                  eligendi similique ducimus nulla, blanditiis, perspiciatis
                  atque saepe! veritatis.
                </p>
              </div>
            </div>
          </div>
          <div className="extra-feature">
            <h6> extra feature</h6>
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <ul className="list-style-1">
                  <li>
                    <i className="fa fa-check" /> Security System
                  </li>
                  <li>
                    <i className="fa fa-check" /> Air conditioning
                  </li>
                  <li>
                    <i className="fa fa-check" /> Alloy Wheels
                  </li>
                  <li>
                    <i className="fa fa-check" /> Anti-Lock Brakes (ABS)
                  </li>
                  <li>
                    <i className="fa fa-check" /> Anti-Theft
                  </li>
                  <li>
                    <i className="fa fa-check" /> Anti-Starter{" "}
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-sm-4">
                <ul className="list-style-1">
                  <li>
                    <i className="fa fa-check" /> Security System
                  </li>
                  <li>
                    <i className="fa fa-check" /> Air conditioning
                  </li>
                  <li>
                    <i className="fa fa-check" /> Alloy Wheels
                  </li>
                  <li>
                    <i className="fa fa-check" /> Anti-Lock Brakes (ABS)
                  </li>
                  <li>
                    <i className="fa fa-check" /> Anti-Theft
                  </li>
                  <li>
                    <i className="fa fa-check" /> Anti-Starter{" "}
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-sm-4">
                <ul className="list-style-1">
                  <li>
                    <i className="fa fa-check" /> Security System
                  </li>
                  <li>
                    <i className="fa fa-check" /> Air conditioning
                  </li>
                  <li>
                    <i className="fa fa-check" /> Alloy Wheels
                  </li>
                  <li>
                    <i className="fa fa-check" /> Anti-Lock Brakes (ABS)
                  </li>
                  <li>
                    <i className="fa fa-check" /> Anti-Theft
                  </li>
                  <li>
                    <i className="fa fa-check" /> Anti-Starter{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="feature-car">
            <h6>Recently Vehicle</h6>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="owl-carousel"
                  data-nav-arrow="true"
                  data-nav-dots="true"
                  data-items={3}
                  data-md-items={3}
                  data-sm-items={2}
                  data-space={15}
                >
                  <div className="item">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          className="img-fluid"
                          src="images/car/01.jpg"
                          alt=""
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-dashboard" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-dashboard" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="#">Acura Rsx</a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">$35,568</span>
                          <span className="new-price">$32,698 </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          className="img-fluid"
                          src="images/car/02.jpg"
                          alt=""
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-dashboard" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-dashboard" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="#">Lexus GS 450h</a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">$35,568</span>
                          <span className="new-price">$32,698 </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          className="img-fluid"
                          src="images/car/03.jpg"
                          alt=""
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-dashboard" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-dashboard" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="#">GTA 5 Lowriders DLC</a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">$35,568</span>
                          <span className="new-price">$32,698 </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="car-item gray-bg text-center">
                      <div className="car-image">
                        <img
                          className="img-fluid"
                          src="images/car/04.jpg"
                          alt=""
                        />
                        <div className="car-overlay-banner">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fa fa-link" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-dashboard" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="car-list">
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-registered" /> 2016
                          </li>
                          <li>
                            <i className="fa fa-cog" /> Manual{" "}
                          </li>
                          <li>
                            <i className="fa fa-dashboard" /> 6,000 mi
                          </li>
                        </ul>
                      </div>
                      <div className="car-content">
                        <div className="star">
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star orange-color" />
                          <i className="fa fa-star-o orange-color" />
                        </div>
                        <a href="#"> Toyota avalon hybrid </a>
                        <div className="separator" />
                        <div className="price">
                          <span className="old-price">$35,568</span>
                          <span className="new-price">$32,698 </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="car-details-sidebar">
            <div className="details-block details-weight">
              <h5>DESCRIPTION</h5>
              <ul>
                <li>
                  {" "}
                  <span>Make</span> <strong className="text-end">BMW</strong>
                </li>
                <li>
                  {" "}
                  <span>Model</span>{" "}
                  <strong className="text-end">7-series</strong>
                </li>
                <li>
                  {" "}
                  <span>Registration date </span>{" "}
                  <strong className="text-end">2021</strong>
                </li>
                <li>
                  {" "}
                  <span>Mileage</span>{" "}
                  <strong className="text-end">25,000 mi</strong>
                </li>
                <li>
                  {" "}
                  <span>Condition</span>{" "}
                  <strong className="text-end">New</strong>
                </li>
                <li>
                  {" "}
                  <span>Exterior Color</span>{" "}
                  <strong className="text-end">Silver</strong>
                </li>
                <li>
                  {" "}
                  <span>Interior Color</span>{" "}
                  <strong className="text-end">Brown (Leather)</strong>
                </li>
                <li>
                  {" "}
                  <span>Transmission</span>{" "}
                  <strong className="text-end">Automatic</strong>
                </li>
                <li>
                  {" "}
                  <span>Engine</span>{" "}
                  <strong className="text-end">5.1 L</strong>
                </li>
                <li>
                  {" "}
                  <span>Drivetrain</span>{" "}
                  <strong className="text-end">FWD</strong>
                </li>
              </ul>
            </div>
            <div className="details-social details-weight">
              <h5>Share now</h5>
              <ul>
                <li>
                  <a href="#">
                    {" "}
                    <i className="fa fa-facebook" /> facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <i className="fa fa-twitter" /> twitter
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <i className="fa fa-pinterest-p" /> pinterest
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <i className="fa fa-dribbble" /> dribbble
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin" /> google plus{" "}
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <i className="fa fa-behance" /> behance
                  </a>
                </li>
              </ul>
            </div>
            <div className="details-form contact-2 details-weight">
              <form
                className="gray-form"
                action="https://themes.potenzaglobalsolutions.com/html/cardealer/post"
                id="send_enquiry_form"
              >
                <h5>SEND ENQUIRY</h5>
                <div
                  id="send_enquiry_notice"
                  className="form-notice"
                  style={{ display: "none" }}
                />
                <input
                  type="hidden"
                  name="action"
                  defaultValue="send_enquiry"
                />
                <div className="mb-3">
                  <label className="form-label">Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="send_enquiry_name"
                    id="send_enquiry_name"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Email address*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    name="send_enquiry_email"
                    id="send_enquiry_email"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Message* </label>
                  <textarea
                    className="form-control"
                    rows={4}
                    placeholder="Message"
                    name="send_enquiry_message"
                    id="send_enquiry_message"
                    defaultValue={""}
                  />
                </div>
                <div className="mb-3">
                  <div id="recaptcha6" />
                </div>
                <div>
                  <a
                    className="button red"
                    id="send_enquiry_submit"
                    href="javascript:void(0)"
                  >
                    Submit{" "}
                    <i
                      className="fa fa-spinner fa-spin fa-fw btn-loader"
                      style={{ display: "none" }}
                    />
                  </a>
                </div>
              </form>
            </div>
            <div className="details-phone details-weight">
              <div className="feature-box-3 grey-border">
                <div className="icon">
                  <i className="fa fa-headphones" />
                </div>
                <div className="content">
                  <h4>1-888-345-888 </h4>
                  <p>Call our seller to get the best price </p>
                </div>
              </div>
            </div>
            <div className="details-form contact-2">
              <form id="financing-calculator-01" className="gray-form">
                <h5>Financing Calculator</h5>
                <div className="mb-3">
                  <label className="form-label">Vehicle Price ($)*</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Price"
                    id="loan-amount"
                    name="loan-amount"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Down Payment *</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Payment"
                    id="down-payment"
                    name="down-payment"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Interest rate (%)*</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Rate"
                    id="interest-rate"
                    name="interest-rate"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Period (Month)*</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Month"
                    id="period"
                    name="period"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Payment</label>
                  <div className="cal_text payment-box">
                    <div id="txtPayment" />
                  </div>
                </div>
                <div>
                  <a
                    className="button red calculate_finance"
                    href="javascript:void(0)"
                    data-form-id="financing-calculator-01"
                  >
                    Estimate Payment
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

    </div>
  )
}

export default Cardetails
