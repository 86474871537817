import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Navbar from './Pages/web_pages/Navbar'
import Footer from './Pages/web_pages/Footer'
import PageNotFound from './Pages/web_pages/ErrorsPages/PageNotFound';
import Home from './Pages/web_pages/Home';
import About from './Pages/web_pages/About';
import Blogs from './Pages/web_pages/Blogs'
import CarList from './Pages/web_pages/CarList';
import Cardetails from './Pages/web_pages/Cardetails'
import Contact from './Pages/web_pages/Contact'
function App() {
   return (
   <>
   <BrowserRouter>
   
   

    <Navbar/>

    {/* Routes */}
   <Routes>

    {/* Web Routes */}
   <Route path='/' element={<Home/>} />   
   <Route path='/home' element={<Home/>} />   
   <Route path='/Blogs' element={<Blogs/>} />   
   <Route path='/About' element={<About/>} /> 
   <Route path='/CarList' element={<CarList/>} /> 
   <Route path='/Cardetails' element={<Cardetails/>} /> 
   <Route path='/Contact' element={<Contact/>} />   
   <Route path='*' element={<PageNotFound/>} />

    {/* User Routes */}
 
    {/* Admin Routes */}
 
   </Routes>

   {/* Footer */}
   <Footer/>



<div className="car-top show car-down">
  <span>
    <img src="images/car.png" alt="" />
  </span>
</div>
<div className="car-top">
  <span>
    <img src="images/car.png" alt="" />
  </span>
</div>

      </BrowserRouter>
   </>
  );
}

export default App;
