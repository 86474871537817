import React from 'react'

function Blogs() {
  return (
    <div>
      <>
  
  <section className="inner-intro bg-1 bg-overlay-black-70">
    <div className="container">
      <div className="row text-center intro-title">
        <div className="col-md-6 text-md-start d-inline-block">
          <h1 className="text-white">blog </h1>
        </div>
        <div className="col-md-6 text-md-end float-end">
          <ul className="page-breadcrumb">
            <li>
              <a href="#">
                <i className="fa fa-home" /> Home
              </a>{" "}
              <i className="fa fa-angle-double-right" />
            </li>
            <li>
              <a href="#">pages</a> <i className="fa fa-angle-double-right" />
            </li>
            <li>
              <span>blog fullwidth</span>{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
  inner-intro */}
  {/*=================================
  blog  */}
  <section className="blog page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>Read what we say in our blog </span>
            <h2>Our blog</h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="blog-entry">
            <div className="blog-entry-image  clearfix">
              <div className="portfolio-item">
                <img className="img-fluid" src="images/blog/02.jpg" alt="" />
              </div>
            </div>
            <div className="entry-title">
              <a href="#">Time to change your</a>
            </div>
            <div className="entry-meta">
              <ul>
                <li>
                  <a href="#">
                    <i className="fa fa-user" /> By Cardealer{" "}
                  </a>{" "}
                  /
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-comments-o" /> 5 Comments
                  </a>{" "}
                  /
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-folder-open" /> News 2016
                  </a>{" "}
                  /
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-heart-o" />
                    10
                  </a>
                </li>
              </ul>
            </div>
            <div className="entry-content">
              <p>
                You will begin to realize why this exercise is called the
                Dickens Pattern with reference to the ghost showing Scrooge some
                different futures as you notice that the idea of this exercise
                is to hypnotize yourself to be aware of two very real
                possibilities for your future. Two distinct pathways that you
                could take for your life this very day. Have a think about
                something that you do that you are maybe not motivated.{" "}
              </p>
            </div>
            <div className="entry-share clearfix">
              <a className="button red float-start" href="#">
                {" "}
                Read More{" "}
              </a>
              <div className="share float-end">
                <a href="#">
                  {" "}
                  <i className="fa fa-share-alt" />{" "}
                </a>
                <div className="blog-social">
                  <ul className="list-style-none">
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-pinterest-p" />
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="gray" />
          {/* ============================================ */}
          <div className="blog-entry">
            <div className="blog-entry-image  clearfix">
              <div className="portfolio-item">
                <img className="img-fluid" src="images/blog/03.jpg" alt="" />
              </div>
            </div>
            <div className="entry-title">
              <a href="#">The best time to</a>
            </div>
            <div className="entry-meta">
              <ul>
                <li>
                  <a href="#">
                    <i className="fa fa-user" /> By Cardealer{" "}
                  </a>{" "}
                  /
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-comments-o" /> 5 Comments
                  </a>{" "}
                  /
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-folder-open" /> News 2016
                  </a>{" "}
                  /
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-heart-o" />
                    10
                  </a>
                </li>
              </ul>
            </div>
            <div className="entry-content">
              <p>
                You will begin to realize why this exercise is called the
                Dickens Pattern with reference to the ghost showing Scrooge some
                different futures as you notice that the idea of this exercise
                is to hypnotize yourself to be aware of two very real
                possibilities for your future. Two distinct pathways that you
                could take for your life this very day. Have a think about
                something that you do that you are maybe not motivated.
              </p>
            </div>
            <div className="entry-share clearfix">
              <a className="button red float-start" href="#">
                {" "}
                Read More{" "}
              </a>
              <div className="share float-end">
                <a href="#">
                  {" "}
                  <i className="fa fa-share-alt" />{" "}
                </a>
                <div className="blog-social">
                  <ul className="list-style-none">
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-pinterest-p" />
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="gray" />
          {/* ============================================ */}
          <div className="blog-entry">
            <div className="blog-entry-image  clearfix">
              <div className="portfolio-item">
                <img className="img-fluid" src="images/blog/04.jpg" alt="" />
              </div>
            </div>
            <div className="entry-title">
              <a href="#">Replacing a timing</a>
            </div>
            <div className="entry-meta">
              <ul>
                <li>
                  <a href="#">
                    <i className="fa fa-user" /> By Cardealer{" "}
                  </a>{" "}
                  /
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-comments-o" /> 5 Comments
                  </a>{" "}
                  /
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-folder-open" /> News 2016
                  </a>{" "}
                  /
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-heart-o" />
                    10
                  </a>
                </li>
              </ul>
            </div>
            <div className="entry-content">
              <p>
                You will begin to realize why this exercise is called the
                Dickens Pattern with reference to the ghost showing Scrooge some
                different futures as you notice that the idea of this exercise
                is to hypnotize yourself to be aware of two very real
                possibilities for your future. Two distinct pathways that you
                could take for your life this very day. Have a think about
                something that you do that you are maybe not motivated.
              </p>
            </div>
            <div className="entry-share clearfix">
              <a className="button red float-start" href="#">
                {" "}
                Read More{" "}
              </a>
              <div className="share float-end">
                <a href="#">
                  {" "}
                  <i className="fa fa-share-alt" />{" "}
                </a>
                <div className="blog-social">
                  <ul className="list-style-none">
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">
                        <i className="fa fa-pinterest-p" />
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="gray" />
          {/* ============================================ */}
          <div className="pagination-nav  d-flex justify-content-center">
            <ul className="pagination">
              <li>
                <a href="#">«</a>
              </li>
              <li className="active">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <a href="#">»</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

      
    </div>
  )
}

export default Blogs
