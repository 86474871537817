import React from 'react'

function About() {
  return (
    <div>
      <>

 
  <section className="inner-intro bg-1 bg-overlay-black-70">
    <div className="container">
      <div className="row text-center intro-title">
        <div className="col-md-6 text-md-start d-inline-block">
          <h1 className="text-white">About us </h1>
        </div>
        <div className="col-md-6 text-md-end float-end">
          <ul className="page-breadcrumb">
            <li>
              <a href="/">
                <i className="fa fa-home" /> Home
              </a>{" "}
            
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 inner-intro */}
  {/*=================================
 welcome */}
  <section className="welcome-4 page-section-ptb white-bg">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="section-title">
            <span>Welcome to </span>
            <h2>Kar Zone, your local used car dealer</h2>
            <div className="separator" />
            <p>
            Find your perfect car at Karzone{" "}
              <strong>Why Buy A car from karzone? {" "}</strong> We guarantee the best used car deals
Choose from all used cars available.
The price you see is the price you pay - no hidden fees
Low-rate car finance available from 9.9% APR
Whether it’s routine servicing, MOTs, mechanical repairs, tyres or parts, get in touch for a competitive quote. And if you’ve had a minor bump or scrape, we can come to you and get your vehicle looking as good as new, from the comfort of your home or office.

Thank you for visiting our website, we hope you find what you’re looking for.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div
            className="owl-carousel owl-theme"
            data-nav-arrow="true"
            data-items={1}
            data-md-items={1}
            data-sm-items={1}
            data-xs-items={1}
            data-space={0}
          >
            <div className="item">
              <img className="img-fluid" src="images/car/15.jpg" alt="" />
            </div>
            <div className="item">
              <img className="img-fluid" src="images/car/16.jpg" alt="" />
            </div>
            <div className="item">
              <img className="img-fluid" src="images/car/17.jpg" alt="" />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <p>
            Dealer obcaecati adipisci vero lorem ipsum dolor sit amet,
            consectetur adipisicing elit. dolorum pariatur aut consectetur. Sit
            quisquam rerum corporis neque atque inventore nulla, quibusdam, ipsa
            suscipit aperiam reiciendis, ea odio?
          </p>
          <br />
          <p>
            Adipisicing ipsum dolor sit amet, consectetur elit. Obcaecati
            adipisci vero dolorum pariatur aut consectetur. Sit quisquam rerum
            corporis neque atque inventore nulla, quibusdam, ipsa suscipit
            aperiam reiciendis, ea odio?
          </p>
          <p>
            Obcaecati adipisci vero dolorum pariatur aut consectetur. lorem
            ipsum dolor sit amet, consectetur adipisicing elit. Sit quisquam
            rerum corporis neque atque inventore nulla, quibusdam, ipsa suscipit
            aperiam reiciendis, ea odio?
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-sm-6">
          <div className="feature-box-3">
            <div className="icon">
              <i className="glyph-icon flaticon-beetle" />
            </div>
            <div className="content">
              <h6>All brands </h6>
              <p className="mb-0">Obcaecati adipisci vero dolorum pariatur </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="feature-box-3">
            <div className="icon">
              <i className="glyph-icon flaticon-interface-1" />
            </div>
            <div className="content">
              <h6>Free Support</h6>
              <p className="mb-0">lorem ipsum dolor sit amet, consectetur</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="feature-box-3">
            <div className="icon">
              <i className="glyph-icon flaticon-key" />
            </div>
            <div className="content">
              <h6>Dealership </h6>
              <p className="mb-0">Sit quisquam rerum corporis neque</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="feature-box-3">
            <div className="icon">
              <i className="glyph-icon flaticon-wallet" />
            </div>
            <div className="content">
              <h6>affordable</h6>
              <p className="mb-0">Suscipit aperiam reiciendis, ea odio?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 welcome */}
  <hr className="gray" />
  {/*=================================
our History */}
  <section className="our-history white-bg page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>Car dealer's 30 year timeline from the beginning</span>
            <h2>our history</h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ul className="timeline list-style-none">
            <li>
              <div className="timeline-badge">
                <h4>01</h4>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h5>the beginning</h5>
                </div>
                <div className="timeline-body">
                  <p>
                    From the beginning lorem ipsum dolor sit amet, consectetur
                    sed do eiusmod tempor incididunt ut labore.
                  </p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-badge">
                <h4>02</h4>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h5>New Service Garage</h5>
                </div>
                <div className="timeline-body">
                  <p>
                    We own our own New Service Garage incididunt Lorem ipsum
                    dolor sit amet consectetur adipiscing elit.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-badge">
                <h4>03</h4>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h5>New Main office</h5>
                </div>
                <div className="timeline-body">
                  <p>
                    With new super cool office elit lorem ipsum dolor sit amet
                    sed do eiusmod tempor incididunt ut labore.
                  </p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-badge">
                <h4>04</h4>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h5>New Website launch</h5>
                </div>
                <div className="timeline-body">
                  <p>
                    Start online business tempor lorem ipsum dolor sit amet,
                    consectetur adipiscing elit incididunt ut labore.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
our History*/}
  {/*=================================
Counter */}
  <section className="counter counter-style-2 bg-red bg-1 bg-overlay-black-70 page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-sm-6 item">
          <div className="counter-block text-start mb-lg-0 mb-4">
            <div className="separator" />
            <div className="info">
              <h6 className="text-white">Vehicles in Stock</h6>
              <i className="glyph-icon flaticon-beetle" />
              <b
                className="timer text-white"
                data-to={561}
                data-speed={10000}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 item">
          <div className="counter-block text-start mb-lg-0 mb-4">
            <div className="separator" />
            <div className="info">
              <h6 className="text-white">Dealer Reviews</h6>
              <i className="glyph-icon flaticon-interface" />
              <b
                className="timer text-white"
                data-to={856}
                data-speed={10000}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 item">
          <div className="counter-block text-start mb-sm-0 mb-4">
            <div className="separator" />
            <div className="info">
              <h6 className="text-white">Happy Customer</h6>
              <i className="glyph-icon flaticon-circle" />
              <b
                className="timer text-white"
                data-to={789}
                data-speed={10000}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 item">
          <div className="counter-block text-start mb-0">
            <div className="separator" />
            <div className="info">
              <h6 className="text-white">Awards</h6>
              <i className="glyph-icon flaticon-cup" />
              <b
                className="timer text-white"
                data-to={356}
                data-speed={10000}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 Counter */}
  {/*=================================
 testimonial */}
  <section className="testimonial-1 white-bg page page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>What Our Happy Clients say about us</span>
            <h2>our Testimonial </h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div
            className="owl-carousel owl-theme"
            data-nav-dots="true"
            data-items={3}
            data-md-items={3}
            data-sm-items={2}
            data-xs-items={2}
            data-space={30}
          >
            <div className="item">
              <div className="testimonial-block text-center">
                <div className="testimonial-image">
                  <img
                    className="img-fluid"
                    src="images/testimonial/01.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-avtar">
                    <img
                      className="img-fluid"
                      src="images/team/01.jpg"
                      alt=""
                    />
                    <h6>Alice Williams</h6>
                    <span>Auto Dealer</span>
                  </div>
                  <div className="testimonial-content">
                    <p>
                      It has survived not only five centuries. lorem Ipsum is
                      simply dummy text of the printin a galley of type and bled
                      it to make a type specimen book.
                    </p>
                    <i className="fa fa-quote-right" />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-block text-center">
                <div className="testimonial-image">
                  <img
                    className="img-fluid"
                    src="images/testimonial/02.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-avtar">
                    <img
                      className="img-fluid"
                      src="images/team/02.jpg"
                      alt=""
                    />
                    <h6>Michael Bean</h6>
                    <span>Car Dealer</span>
                  </div>
                  <div className="testimonial-content">
                    <p>
                      A galley of type and bled it to make a type specimen book.
                      Ipsum is simply dummy text of the printin It has survived
                      not only five centuries.
                    </p>
                    <i className="fa fa-quote-right" />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-block text-center">
                <div className="testimonial-image">
                  <img
                    className="img-fluid"
                    src="images/testimonial/03.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-avtar">
                    <img
                      className="img-fluid"
                      src="images/team/03.jpg"
                      alt=""
                    />
                    <h6>Felica Queen</h6>
                    <span>Auto Dealer</span>
                  </div>
                  <div className="testimonial-content">
                    <p>
                      Text of the printin a galley of type and bled it to a type
                      specimen book. It has survived not only five centuries
                      make Lorem Ipsum is simply dummy.
                    </p>
                    <i className="fa fa-quote-right" />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-block text-center">
                <div className="testimonial-image">
                  <img
                    className="img-fluid"
                    src="images/testimonial/04.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-avtar">
                    <img
                      className="img-fluid"
                      src="images/team/04.jpg"
                      alt=""
                    />
                    <h6>Sara Lisbon</h6>
                    <span>Customer</span>
                  </div>
                  <div className="testimonial-content">
                    <p>
                      Printin a galley of type and bled It has survived not
                      lorem Ipsum is simply dummy text of the it to make a type
                      specimen book only five centuries.
                    </p>
                    <i className="fa fa-quote-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

    </div>
  )
}

export default About
